.shemsu-img {
  height: 50vh;
}

.errorbody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
