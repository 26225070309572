.shemsu-img {
  height: 50vh;
}

.errorbody {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}
/*# sourceMappingURL=error.72f49d2d.css.map */
